<template>
  <b-card no-body class="h-100">
    <!-- IF MEDIA SELECTED IS KONVENSIONAL -->
    <div class="row mx-1 mt-2">
      <div class="col-md-7 btn-switch">
        <!-- MEDIA KONVENSIONAL TOGGLE BUTTON -->
        <b-button
          variant="flat"
          class="btn-sm w-100"
          :class="isAllMedkon ? 'bg-light-primary' : 'bg-light-secondary'"
          pill
          @click="filterMedkon()"
        >
          Media Konvensional
        </b-button>
      </div>
      <div class="col-md-5 btn-switch">
        <!-- MEDIA SOSIAL TOGGLE BUTTON -->
        <b-button
          variant="flat"
          class="btn-sm w-100"
          :class="isAllMedsos ? 'bg-light-primary' : 'bg-light-secondary'"
          pill
          @click="filterMedsos()"
        >
          Media Sosial
        </b-button>
      </div>
    </div>
    <b-card-body>
      <div
        v-for="medkon in medkon"
        :key="medkon.name"
        class="klik clickable rounded"
        :class="medkon.klik ? 'active' : ''"
      >
        <div
          class="d-flex align-items-center pxy rounded"
          @click="(medkon.klik = !medkon.klik), medkonClick()"
        >
          <b-media-aside>
            <!-- MEDIA AVATAR -->
            <b-avatar rounded size="35" variant="light-primary">
              <!-- MEDIA ONLINE -->
              <i
                v-if="medkon.name == 'Media Online'"
                class="bi-globe media-online-color fa-xl"
              ></i>

              <!-- MEDIA CETAK -->
              <i
                v-else-if="medkon.name == 'Media Cetak'"
                class="bi-newspaper media-cetak-color fa-xl"
              ></i>

              <!-- MEDIA ELEKTRONIK -->
              <i
                v-else-if="medkon.name == 'Media Elektronik'"
                class="bi-display media-elektronik-color fa-xl"
              ></i>
            </b-avatar>
          </b-media-aside>
          <b-media-body>
            <div class="d-flex">
              <!-- MEDIA NAME -->
              <div class="mr-auto">
                {{ medkon.name }}
              </div>

              <!-- MEDIA VALUE -->
              <div class="font-weight-bolder ml-auto">
                {{ medkon.data }}
              </div>
            </div>
          </b-media-body>
        </div>
        <hr style="margin-top: -2px" />
      </div>
      <div
        v-for="medsos in medsos"
        :key="medsos.name"
        class="klik clickable rounded"
        :class="medsos.klik ? 'active' : ''"
      >
        <div
          class="d-flex align-items-center pxy rounded"
          @click="(medsos.klik = !medsos.klik), medsosClick()"
        >
          <b-media-aside>
            <!-- MEDIA AVATAR -->
            <b-avatar rounded size="35" variant="light-primary">
              <!-- TWITTER -->
              <i
                v-if="medsos.name == 'Twitter'"
                class="bi-twitter twitter-color fa-xl"
              ></i>

              <!-- FACEBOOK -->
              <i
                v-else-if="medsos.name == 'Facebook'"
                class="bi-facebook facebook-color fa-xl"
              ></i>

              <!-- INSTAGRAM -->
              <i
                v-else-if="medsos.name == 'Instagram'"
                class="bi-instagram instagram-color fa-xl"
              ></i>

              <!-- TIKTOK -->
              <i
                v-else-if="medsos.name == 'Tiktok'"
                class="bi-tiktok tiktok-color fa-xl"
              ></i>

              <!-- YOUTUBE -->
              <i
                v-else-if="medsos.name == 'Youtube'"
                class="bi-youtube youtube-color fa-xl"
              ></i>

              <!-- LINKEDIN -->
              <i
                v-else-if="medsos.name == 'Linkedin'"
                class="bi-linkedin linkedin-color fa-xl"
              ></i>

              <!-- FORUM -->
              <i
                v-else-if="medsos.name == 'Forum'"
                class="fa-solid fa-comments forum-color fa-xl"
              ></i>

              <!-- BLOG -->
              <i
                v-else-if="medsos.name == 'Blog'"
                class="bi-rss-fill blog-color fa-xl"
              ></i>
            </b-avatar>
          </b-media-aside>
          <b-media-body>
            <div class="d-flex">
              <!-- MEDIA NAME -->
              <div class="mr-auto">
                {{ medsos.name }}
              </div>

              <!-- MEDIA VALUE -->
              <div class="font-weight-bolder ml-auto">
                {{ medsos.data }}
              </div>
            </div>
          </b-media-body>
        </div>
        <hr style="margin-top: -2px" />
      </div>
    </b-card-body>
  </b-card>
</template>
<script>
import {
  BCard,
  BCardHeader,
  BButton,
  BCardBody,
  BMedia,
  BMediaBody,
  BMediaAside,
  BAvatar,
  BProgress,
  BProgressBar,
} from "bootstrap-vue";

export default {
  name: "cardMention",
  components: {
    BCard,
    BCardHeader,
    BButton,
    BCardBody,
    BMedia,
    BMediaBody,
    BMediaAside,
    BAvatar,
    BProgress,
    BProgressBar,
  },

  data: () => ({
    isAllMedkon: false,
    isAllMedsos: false,
    medkon: [
      {
        name: "Media Online",
        data: "83,5k",
        klik: false,
      },
      {
        name: "Media Cetak",
        data: "83,5k",
        klik: false,
      },
      {
        name: "Media Elektronik",
        data: "83,5k",
        klik: false,
      },
    ],
    medsos: [
      {
        name: "Twitter",
        data: "83,5k",
        klik: false,
      },
      {
        name: "Facebook",
        data: "83,5k",
        klik: false,
      },
      {
        name: "Instagram",
        data: "83,5k",
        klik: false,
      },
      {
        name: "Tiktok",
        data: "83,5k",
        klik: false,
      },
      {
        name: "Youtube",
        data: "83,5k",
        klik: false,
      },
      {
        name: "Linkedin",
        data: "83,5k",
        klik: false,
      },
      {
        name: "Forum",
        data: "83,5k",
        klik: false,
      },
      {
        name: "Blog",
        data: "83,5k",
        klik: false,
      },
    ],
  }),

  methods: {
    filterMedkon() {
      if (this.isAllMedkon == false) {
        this.isAllMedkon = true;
        this.medkon.forEach((medkon) => (medkon.klik = true));
      } else {
        this.isAllMedkon = false;
        this.medkon.forEach((medkon) => (medkon.klik = false));
      }
    },
    filterMedsos() {
      if (this.isAllMedsos == false) {
        this.isAllMedsos = true;
        this.medsos.forEach((medsos) => (medsos.klik = true));
      } else {
        this.isAllMedsos = false;
        this.medsos.forEach((medsos) => (medsos.klik = false));
      }
    },
    medkonClick() {
      if (this.isAllMedkon == true) {
        this.isAllMedkon = false;
      }
    },
    medsosClick() {
      if (this.isAllMedsos == true) {
        this.isAllMedsos = false;
      }
    },
  },
};
</script>
<style scoped>
.btn-switch :hover {
  opacity: 0.7;
}
.klik :hover {
  background-color: #ecf5f8;
  border-radius: 10px;
}
.pxy {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
}
.active {
  background-color: #d3eeff;
  border-radius: 5px;
}
.dark-layout * .active {
  background-color: #161d31;
  border-radius: 5px;
}
.dark-layout * .klik :hover {
  background-color: #202940;
  border-radius: 5px;
}
</style>