<template>
  <div class="mention">
    <b-card no-body class="h-100">
      <div class="d-flex my-2 mx-1">
        <!-- FILTER LATEST -->
        <b-input-group class="mr-2 vue-select" style="width: 15%">
          <b-input-group-prepend
            is-text
            style="position: absolute; height: 100%"
          >
            <i class="bi bi-sort-down"></i>
          </b-input-group-prepend>
          <v-select
            v-model="selected_waktu"
            label="title"
            :options="waktu"
            placeholder="Terbaru"
            style="width: 100%"
          />
        </b-input-group>

        <!-- FILTER PER PAGE -->
        <b-input-group class="mr-2 vue-select" style="width: 17%">
          <b-input-group-prepend
            is-text
            style="position: absolute; height: 100%"
          >
            <i class="fa-solid fa-pager"></i>
          </b-input-group-prepend>
          <v-select
            v-model="selected_halaman"
            label="title"
            :options="halaman"
            placeholder="10 Data/ Halaman"
            style="width: 100%"
          />
        </b-input-group>

        <!-- FILTER JENIS AKUN -->
        <b-input-group class="mr-2 vue-select" style="width: 17%">
          <b-input-group-prepend
            is-text
            style="position: absolute; height: 100%"
          >
            <feather-icon icon="EyeIcon" />
          </b-input-group-prepend>
          <v-select
            v-model="selected_jenis_akun"
            label="title"
            :options="jenis_akun"
            placeholder="Jenis Akun"
            style="width: 100%"
          />
        </b-input-group>

        <!-- FILTER TIER MEDIA -->
        <b-input-group class="mr-2 vue-select" style="width: 15%">
          <b-input-group-prepend
            is-text
            style="position: absolute; height: 100%"
          >
            <feather-icon icon="LayersIcon" />
          </b-input-group-prepend>
          <v-select
            v-model="selected_tier"
            label="title"
            :options="tier"
            placeholder="Tier Media"
            style="width: 100%"
          />
        </b-input-group>

        <!-- HIGHLIGHT -->
        <b-button
          v-if="highlight"
          variant="outline-secondary"
          v-b-tooltip.hover.v-dark="'Highlight'"
          class="highlight-button mr-2 btn-icon"
          size="sm"
          @click="highlight = false"
        >
          <i
            class="bi bi-star-fill text-warning fa-lg"
            style="padding-left: 3px; padding-right: 3px"
          ></i>
        </b-button>
        <b-button
          v-else
          variant="outline-secondary"
          v-b-tooltip.hover.v-dark="'Highlight'"
          class="highlight-button mr-2 btn-icon"
          size="sm"
          @click="highlight = true"
        >
          <i
            class="bi bi-star fa-lg"
            style="padding-left: 3px; padding-right: 3px"
          ></i>
        </b-button>

        <!-- FORM SEARCH -->
        <b-input-group class="input-group-merge ml-auto" style="width: 25%">
          <b-input-group-prepend is-text>
            <feather-icon icon="SearchIcon" class="text-primary" />
          </b-input-group-prepend>
          <b-form-input placeholder="Cari" />
        </b-input-group>
      </div>

      <!-- BUTTON -->
      <div class="d-flex align-items-center mx-1 mb-2">
        <!-- EXCEL -->
        <b-button variant="outline-primary" class="mr-3">
          <i class="fa-solid fa-file-excel fa-lg mr-50"></i>
          Raw Data Excel
        </b-button>

        <!-- PDF -->
        <b-button variant="outline-primary" class="mr-3">
          <i class="fa-solid fa-file-pdf fa-lg mr-50"></i>
          Kliping PDF
        </b-button>

        <!-- WORD -->
        <b-button variant="outline-primary" class="mr-3">
          <i class="fa-solid fa-file-word fa-lg mr-50"></i>
          Kliping Ms. Word
        </b-button>

        <!-- SHARE -->
        <b-button variant="outline-primary" class="mr-3">
          <i class="fa-solid fa-share fa-lg mr-50"></i>
          Bagikan Berita
        </b-button>

        <!-- DELETE -->
        <b-button variant="outline-danger">
          <i class="fa-solid fa-trash mr-50"></i>
          Hapus Berita
        </b-button>

        <!-- CHECKBOX -->
        <div class="d-flex align-items-center ml-auto">
          <span class="mr-50">Pilih Semua</span>
          <b-form-checkbox
            class="custom-control-primary ml-auto"
            @change="checkAll()"
            :checked="allItemsChecked"
          >
          </b-form-checkbox>
        </div>
      </div>
    </b-card>

    <!-- ITEMS -->
    <div class="row">
      <!-- MEDIA SELECT -->
      <div class="col-md-4">
        <cardMention />
      </div>

      <!-- ITEM NEWS CARD -->
      <div class="col-md-8">
        <div v-for="item in item_pages" :key="item.name">
          <div class="mt-1 forAbsolute">
            <b-form-checkbox
              class="custom-control-primary"
              :checked="item.checked"
              @change="toggleSelect(item)"
            >
            </b-form-checkbox>
          </div>
          <newsMedsosCard />
        </div>

        <!-- PAGINATION -->
        <div class="row">
          <div class="col-md-12 float-right">
            <b-pagination
              v-model="current_page"
              :total-rows="items.length"
              :per-page="per_page"
              align="right"
              size="sm"
              @change="updatePage"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  BCard,
  BCardHeader,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
  BButton,
  BFormCheckbox,
  BPagination,
  VBTooltip,
} from "bootstrap-vue";
import vSelect from "vue-select";
import cardMention from "@/components/mention/cardMention.vue";
import newsMedsosCard from "@/components/usable/newsMedsosCard.vue";

export default {
  name: "Mention",
  components: {
    BCard,
    BCardHeader,
    BInputGroup,
    BInputGroupPrepend,
    VBTooltip,
    BFormInput,
    BButton,
    BFormCheckbox,
    BPagination,
    vSelect,
    cardMention,
    newsMedsosCard,
  },

  data() {
    return {
      title: "Mention | Online Media Monitoring",
      media: "konvensional",
      highlight: false,
      selected_waktu: null,
      selected_halaman: null,
      selected_jenis_akun: null,
      selected_tier: null,
      waktu: [
        { title: "Terpopuler" },
        { title: "Terbaru" },
        { title: "Terlama" },
        { title: "Engangement" },
      ],
      halaman: [
        { title: "10 Post/Halaman" },
        { title: "25 Post/Halaman" },
        { title: "50 Post/Halaman" },
        { title: "100 Post/Halaman" },
        { title: "500 Post/Halaman" },
        { title: "1000 Post/Halaman" },
      ],
      jenis_akun: [
        { title: "Tampilkan Semua" },
        { title: "Tampilkan Pers" },
        { title: "Tampilkan Non Pers" },
      ],
      tier: [{ title: "Semua Tier" }, { title: "Tier 1" }, { title: "Tier 2" }],
      current_page: 1,
      per_page: 5,
      item_pages: [],
      items: [
        {
          name: "1",
          checked: false,
        },
        {
          name: "2",
          checked: false,
        },
        {
          name: "3",
          checked: false,
        },
        {
          name: "4",
          checked: false,
        },
        {
          name: "5",
          checked: false,
        },
        {
          name: "6",
          checked: false,
        },
        {
          name: "7",
          checked: false,
        },
        {
          name: "8",
          checked: false,
        },
        {
          name: "9",
          checked: false,
        },
        {
          name: "10",
          checked: false,
        },
        {
          name: "11",
          checked: false,
        },
        {
          name: "12",
          checked: false,
        },
        {
          name: "13",
          checked: false,
        },
      ],
    };
  },

  created() {
    document.title = this.title;
  },

  mounted() {
    this.updateItemPages();
  },

  methods: {
    // CHECK NEWS ITEM
    toggleSelect(item) {
      item.checked = !item.checked;
      this.button = "Hapus yang Ditandai";
    },
    // CHECK ALL NEWS ITEM
    checkAll() {
      let futureCheckedValue = true;
      this.button = "Hapus Semua";

      if (this.allItemsChecked) {
        futureCheckedValue = false;
        this.button = "Hapus Semua";
      }

      this.items.forEach((item) => (item.checked = futureCheckedValue));
    },
    // DELETE ALL NEWS ITEM
    deleteAll() {
      let varDelete = this.items.filter((el) => el.checked == true);
      console.log(varDelete);
    },
    // UPDATE PAGE
    updatePage(page_number) {
      this.current_page = page_number;
      this.updateItemPages();
    },
    // UPDATE ITEMS OF PAGE
    updateItemPages() {
      this.item_pages = this.items.slice(
        (this.current_page - 1) * this.per_page,
        (this.current_page - 1) * this.per_page + this.per_page
      );
      if (this.item_pages.length == 0 && this.current_pages == 0) {
        this.updatePage(this.current_pages - 1);
      }
    },
  },

  computed: {
    // NEWS ITEM IS CHECKED
    checkedLength() {
      return this.items.filter((item) => item.checked).length;
    },
    // ALL NEWS ITEM IS CHECKED
    allItemsChecked() {
      return this.checkedLength === this.items.length;
    },
  },

  directives: {
    "b-tooltip": VBTooltip,
  },
};
</script>
<style>
.mention * .vs__search {
  margin-left: 25px;
}
.mention * .vs__selected {
  margin-left: 25px;
}
.dark-layout
  .h-100
  .content
  .content-wrapper
  .content-body
  .mention
  .card
  .d-flex
  .vue-select
  .input-group-prepend
  .input-group-text {
  color: #d0d2d6 !important;
  position: absolute !important;
  background-color: transparent !important;
  border-color: transparent !important;
  z-index: 3 !important;
  height: 100% !important;
}
.dark-layout
  .h-100
  .content
  .content-wrapper
  .content-body
  .mention
  .card
  .d-flex
  .vue-select
  .input-group-append
  .input-group-text {
  color: #d0d2d6 !important;
}
.highlight-button {
  border-color: #c8d2d8 !important;
}
.dark-layout * .highlight-button {
  border-color: #505054 !important;
}
.forAbsolute {
  position: absolute;
  z-index: 2;
  right: 0;
  margin-right: 28px;
  border-radius: 10px;
}
</style>
